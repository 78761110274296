import React from "react";
import Base from "./Base";
import { useDocumentTitle, useRemoteData } from "../hooks";
import Resource from "../components/Resource";

const ResourcesPage = () => {

  const data = useRemoteData("resources/");

  useDocumentTitle("SIDB - Resources");

  if (!data) return <Base loading={true} />;

  return (
    <Base className="py-6 sm:py-12">
      <div className="page-width">
        <h1 className="heading mb-16 text-4xl sm:mb-24 sm:text-5xl">Resources</h1>
        <div className="flex flex-wrap gap-x-8 gap-y-12 justify-center sm:gap-x-12 sm:gap-y-16 lg:gap-x-24 lg:gap-y-32">
          {data.map((resource, index) => <Resource key={index} resource={resource} />)}
        </div>
      </div>
    </Base>
  );
};

ResourcesPage.propTypes = {
  
};

export default ResourcesPage;