import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const GroupBackground = props => {

  const { backgroundHtml } = props;
  
  const textClass = "leading-relaxed text-gray-500 mb-2 mt-2 sm:text-lg lg:mb-3";
  const liClass = textClass.replace("mb-2", "mb-1").replace("mt-2", "mt-1").replace("mb-3", "mb-2");

  const parseBackgroundHtml = parseHtml(backgroundHtml, { p: textClass, li: liClass });

  return (
    <div className="bg-gray-100 py-8 sm:py-12">
      <div className="page-width">
        <h2 className="heading mb-4 sm:mb-6">Background</h2>
        <div dangerouslySetInnerHTML={{ __html: parseBackgroundHtml }} />
      </div>
    </div>
  );
};

GroupBackground.propTypes = {
  backgroundHtml: PropTypes.string.isRequired
};

export default GroupBackground;