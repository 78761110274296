import React from "react";
import { useDocumentTitle, useRemoteData } from "../hooks";
import { useParams } from "react-router-dom";
import Base from "./Base";
import BlogPost from "../components/BlogPost";
import RecentPosts from "../components/RecentPosts";
import PageNotFound from "./PageNotFound";

const BlogPostPage = () => {

  const { slug } = useParams();

  const data = useRemoteData(`news-events/${slug}/`);

  useDocumentTitle(data ? `SIDB - ${data.title}` : "SIDB");

  if (!data) return <Base loading={true} />;

  if (data.error) return <PageNotFound />;

  return (
    <Base className="py-12 sm:py-16">
      <div className="page-width flex flex-col gap-8 lg:flex-row">
        <BlogPost post={data} className="border-b pb-8 flex-grow lg:border-r lg:border-b-0 lg:pr-8 lg:pb-0" />
        <RecentPosts posts={data.recent_posts} />
      </div>
    </Base>
  );
};

BlogPostPage.propTypes = {
  
};

export default BlogPostPage;