import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";
import ResearchImages from "./ResearchImages";

const PhdDeadline = props => {

  const { images, html } = props;

  const textClass = "mb-2 text-gray-500 leading-relaxed sm:text-lg sm:mb-3 sm:leading-relaxed";

  const parsedHtml = parseHtml(html, { p: textClass });

  return (
    <div className="pb-12 sm:pb-16">
      <ResearchImages images={images} />
      <div className="page-width mt-12 sm:mt-16">
        <h2 className="font-league font-semibold text-2xl mb-2 lg:text-3xl sm:mb-3">Application & deadline</h2>
        <div dangerouslySetInnerHTML={{ __html: parsedHtml }} />
      </div>
    </div>
  );
};

PhdDeadline.propTypes = {
  image: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired
};

export default PhdDeadline;