import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const NewsIntro = props => {

  const { introHtml } = props;

  const textClass = "text-lg mb-2 text-gray-500 leading-relaxed sm:text-xl sm:mb-4 sm:leading-relaxed";

  const parsedIntroHtml = parseHtml(introHtml, { p: textClass });

  return (
    <div className="page-width py-12 sm:py-16">
      <h1 className="font-semibold font-league text-4xl mb-4 sm:text-5xl">From SIDB into the World</h1>
      <div dangerouslySetInnerHTML={{ __html: parsedIntroHtml }} />
    </div>
  );
};

NewsIntro.propTypes = {
  introHtml: PropTypes.string.isRequired
};

export default NewsIntro;