import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const NewsGrid = props => {

  const {
    resourcesHtml, communityEventsHtml, seminarsTalksHtml,
    resourcesImage, communityEventsImage, seminarsTalksImage
  } = props;

  const imgClass = "w-56 h-36 object-cover object-center mb-4 sm:w-72 sm:mb-6 md:w-48 lg:w-64 xl:w-72 2xl:w-80 2xl:h-56";
  const headingClass = "font-league font-semibold text-2xl text-center mb-2 2xl:text-3xl";
  const infoClass = "text-center text-gray-500";
  const textClass = "text-sm w-56 sm:text-base sm:w-72 md:w-48 md:text-sm lg:text-base lg:w-64 xl:1-72 2xl:w-80 xl:w-72 2xl:text-lg";

  const parsedResourcesHtml = parseHtml(resourcesHtml, { p: textClass });
  const parsedCommunityEventsHtml = parseHtml(communityEventsHtml, { p: textClass });
  const parsedSeminarsTalksHtml = parseHtml(seminarsTalksHtml, { p: textClass });

  return (
    <div className="page-width mb-6 sm:mb-12">
      <div className="bg-pale-blue py-12 sm:py-16 2xl:py-24">
        <div className="w-fit mx-auto grid grid-cols-1 gap-12 md:grid-cols-3">
          <div>
            <img src={`${process.env.REACT_APP_MEDIA_URL}/${resourcesImage}`} className={imgClass} alt={"resources"} />
            <div className={headingClass}>Resources</div>
            <div className={infoClass} dangerouslySetInnerHTML={{ __html: parsedResourcesHtml }} />
          </div>
          <div>
            <img src={`${process.env.REACT_APP_MEDIA_URL}/${communityEventsImage}`} className={imgClass} alt="community" />
            <div className={headingClass}>Community events</div>
            <div className={infoClass} dangerouslySetInnerHTML={{ __html: parsedCommunityEventsHtml }} />
          </div>
          <div>
            <img src={`${process.env.REACT_APP_MEDIA_URL}/${seminarsTalksImage}`} className={imgClass} alt="seminars" />
            <div className={headingClass}>Seminars & talks</div>
            <div className={infoClass} dangerouslySetInnerHTML={{ __html: parsedSeminarsTalksHtml }} />
          </div>
        </div>
      </div>
    </div>
  );
};

NewsGrid.propTypes = {
  resourcesHtml: PropTypes.string.isRequired,
  communityEventsHtml: PropTypes.string.isRequired,
  seminarsTalksHtml: PropTypes.string.isRequired,
  resourcesImage: PropTypes.string.isRequired,
  communityEventsImage: PropTypes.string.isRequired,
  seminarsTalksImage: PropTypes.string.isRequired,
};

export default NewsGrid;