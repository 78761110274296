import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const Background = props => {

  const { html } = props;

  const textClass = "leading-relaxed text-gray-500 mb-3 mt-2 sm:text-lg sm:leading-relaxed lg:mb-4";

  const parsedHtml = parseHtml(html, { p: textClass });

  return (
    <div className="page-width py-6 sm:py-12">
      <h1 className="heading mb-2 sm:mb-4">Background</h1>
      <div dangerouslySetInnerHTML={{ __html: parsedHtml }} />
    </div>
  );
};

Background.propTypes = {
  html: PropTypes.string.isRequired
};

export default Background;