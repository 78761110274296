import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import defaultImg from "../images/default.png";

const GroupLeaders = props => {

  const { people } = props;

  return (
    <div className="flex flex-wrap gap-x-6 gap-y-16 justify-center mx-auto w-fit sm:gap-y-20 md:gap-y-24 md:gap-x-10 xl:gap-x-16 xl:gap-y-28">
      {people.map((person, index) => (
        <div key={index} className="flex flex-col justify-between items-center w-44 lg:w-48 xl:w-60">
          <div className="flex flex-col items-center">
            <img
              src={person.image ? `${process.env.REACT_APP_MEDIA_URL}/${person.image}` : defaultImg} alt={person.last_name}
              className="w-44 h-44 rounded-full mb-4 object-cover inline-block lg:w-48 lg:h-48 xl:w-60 xl:h-60"
            />
            <div className="font-league font-semibold text-2xl text-center mb-1">
              {person.first_name} {person.last_name}
            </div>
            <div className="text-gray-600 mb-5 text-center">{person.role}</div>
          </div>
          <Link
            to={`/${person.slug}/`} 
            className="block-link"
          >
            {person.last_name} Lab
          </Link>
        </div>
      ))}
    </div>
  );
};

GroupLeaders.propTypes = {
  people: PropTypes.array.isRequired
};

export default GroupLeaders;