import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const Vacancies = props => {

  const { groups } = props;

  const textClass = "leading-relaxed text-gray-600 mb-2 mt-2 sm:text-lg lg:mb-3";

  return (
    <div className="page-width py-12 flex flex-col gap-12 sm:gap-16 sm:py-16 md:gap-20 md:py-20">
      {groups.map((group, index) => (
        <div key={index} className="">
          <div className="heading text-3xl mb-6 sm:text-4xl">{group.last_name} Lab</div>
          <div className="flex flex-col gap-y-6 gap-x-12 lg:flex-row">
            <div>
              <div className="flex flex-col gap-6">
                {group.vacancies.map((vacancy, index) => {
                  const html = parseHtml(vacancy.text, { p: textClass})
                  return (
                    <div key={index} className="mb-2">
                      <div className="font-semibold text-gray-500 text-xl mb-2 sm:mb-4 sm:text-2xl">{vacancy.title}</div>
                      <div dangerouslySetInnerHTML={{__html: html}} />
                    </div>
                  )
                })}
              </div>
              {group.url && (
                <div className={`${textClass} mt-6`}>
                  To find out more about the lab, visit their website <a href={group.url} className="link">here</a>.
                </div>
              )}
            </div>
            <div>
              <div className="font-semibold font-league text-2xl mb-6">Apply here</div>
              <a href={`mailto:${group.email}`} className="block-link whitespace-nowrap">
                Email {group.first_name} {group.last_name}
              </a>
            </div>
          </div>
        </div> 
      ))}
    </div>
  );
};

Vacancies.propTypes = {
  groups: PropTypes.array.isRequired
};

export default Vacancies;