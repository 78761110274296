import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";
import { Link } from "react-router-dom";

const Studentships = props => {

  const { html, open_projects_title, phd_projects } = props;

  const textClass = "leading-relaxed text-gray-500 mb-2 mt-2 sm:text-lg lg:mb-3";
  const parsedHtml = parseHtml(html, { p: textClass });

  return (
    <div className="py-6 sm:py-12">
      <div className="page-width">
        <h1 className="heading mb-4 sm:mb-6">SIDB PhD Studentships</h1>
        <div className="font-league font-semibold text-2xl mb-2 sm:text-3xl sm:mb-4">About the programme</div>
        <div dangerouslySetInnerHTML={{__html: parsedHtml}} />
        {open_projects_title && (
          <h2 className="heading border-b-0 text-lg mt-4 mb-1 sm:mt-8 sm:text-xl sm:mb-2">{open_projects_title}</h2>
        )}
        {phd_projects.length > 0 && (
          <div className="flex flex-col gap-4">
            {phd_projects.map((project, index) => (
              <Link className="block group" key={index} to={`/phds/${project.slug}/`}>
                <h3 className="text-lg mb-2 transition duration-300 sm:text-xl group-hover:text-orange-2">{project.title}</h3>
                <div className="leading-relaxed text-gray-500 mb-3 sm:text-lg sm:leading-relaxed">{project.description}</div>
              </Link> 
            ))}
          </div> 
        )}
      </div>
    </div>
  );
};

Studentships.propTypes = {
  html: PropTypes.string.isRequired
};

export default Studentships;