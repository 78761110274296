import React, { useEffect } from "react";
import Base from "./Base";
import { useMatch } from "react-router-dom";
import { useDocumentTitle, useRemoteData } from "../hooks";
import GroupLeaders from "../components/GroupLeaders";
import LabMembers from "../components/LabMembers";
import ExecutiveTeam from "../components/ExecutiveTeam";
import PeopleSwitcher from "../components/PeopleSwitcher";
import Funding from "../components/Funding";

const PeoplePage = () => {

  const category = useMatch("people/:category/").params.category;
  
  const data = useRemoteData("people/?role=" + category);

  const heading = {
    "group-leaders": "Group Leaders",
    "researchers": "Researchers",
    "professional-scientific-staff": "Professional & Scientific Staff",
    "phd-students": "PhD Students",
    "executive-team": "Funders & Governance",
  }[category];

  useDocumentTitle(`SIDB - ${heading}`);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const scroll = urlParams.get("scroll");
    if (scroll) {
      const element = document.getElementById(scroll);
      if (element) {
        const yOffset = -100; 
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({top: y, behavior: "smooth"});
      }
    }
  }, [data]);

  if (!data || data.role !== category) return <Base loading={true} />;
  
  const Component = category === "group-leaders" ? GroupLeaders : category === "executive-team" ? ExecutiveTeam : LabMembers;

  return (
    <Base className="py-12 sm:py-16">
      <div className="page-width">
        <PeopleSwitcher />
        <h1 className="heading text-4xl mb-10 sm:mb-16 sm:text-5xl">{heading}</h1>
        {category !== "executive-team" && <Component people={data.people} link={true} />}
        {category === "executive-team" && (
          <Funding
            fundingHtml={data.people.funding}
            externalHtml={data.people.external_advisory_board}
            strategicMembers={data.people.strategic_board_members}
            externalMembers={data.people.external_board_members}
          />
        )}
      </div>
    </Base>
  );
};

PeoplePage.propTypes = {
  
};

export default PeoplePage;