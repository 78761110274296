import { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as LeftArrow } from "../images/left.svg";

const ResearchImages = props => {

  const { images, grid } = props;

  const [index, setIndex] = useState(null);

  const toPrevious = () => {
    if (index === 0) {
      setIndex(images.length - 1);
    } else {
      setIndex(index - 1);
    }
  };

  const toNext = () => {
    if (index === images.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };
  
  const arrowClass = "h-8 w-8 cursor-pointer";
  const columnClass = "w-44 h-full flex items-center justify-center";


  return (
    <div className={grid ? "grid gap-2 sm:grid-cols-2 lg:grid-cols-3 page-width py-6 sm:py-12" : "flex"}>
      {images.map((image, index) => {
        const src = `${process.env.REACT_APP_MEDIA_URL}/${image.image}`;
        return (
          <div className="relative w-full h-48 overflow-hidden cursor-pointer bg-gray-500 sm:h-56 md:h-64 lg:h-72 2xl:h-96" key={index}>
            <img
              alt={image.title}
              src={src} onClick={() => setIndex(index)}
              className="w-full h-full object-cover transition-all duration-1000 ease-in-out hover:scale-110"
            />
          </div>
        );
      })}
      {index !== null && (
        <div className="fixed top-0 left-0 w-full h-full bg-black backdrop-blur bg-opacity-50 flex items-center justify-center z-50">
          <div className={columnClass}>
            {images.length > 1 && (
              <>
                <div className="text-white absolute top-12 text-xl sm:text-3xl">{index + 1} / {images.length}</div>
                <LeftArrow fill="white" className={arrowClass} onClick={toPrevious} />
              </>
            )}
          </div>
          <div className="flex-grow h-full justify-center items-center py-12 flex flex-col">
            <img src={`${process.env.REACT_APP_MEDIA_URL}/${images[index].image}`} alt={images[index].title} className="max-w-full max-h-full" />
            <div className="text-white mt-2 text-center italic max-w-3xl">{images[index].title}</div>
          </div>
          <div className={columnClass}>
            <div onClick={() => setIndex(null)} className="absolute top-9 cursor-pointer text-white text-5xl sm:top-8 sm:text-7xl">&times;</div>
            {images.length > 1 && (
              <LeftArrow fill="white" className={`${arrowClass} rotate-180`} onClick={toNext} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ResearchImages.propTypes = {
  images: PropTypes.array.isRequired,
  grid: PropTypes.bool
};

export default ResearchImages;