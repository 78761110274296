export const parseHtml = (html, classes, wideBerth) => {
  /**
   * Parse HTML string and add classes to elements.
   */

  const allClasses = {
    a: "link",
    h3: "font-league font-semibold text-2xl mb-2 sm:text-3xl sm:mb-4",
    h4: "font-league font-semibold text-gray-700 text-xl mb-2 sm:text-2xl sm:mb-4",
  }
  for (const [key, value] of Object.entries(classes)) {
    if (allClasses[key]) {
      allClasses[key] += ` ${value}`;
    } else {
      allClasses[key] = value;
    }
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(
    html.replace(/href="\/documents\/\d+\/([^"]+)"/g, 'href="/documents/$1"'),
    "text/html"
  );
  Object.entries(allClasses).forEach(([tag, classList]) => {
    doc.querySelectorAll(tag).forEach(el => {
      el.classList.add(...classList.split(" "));
    });
  });
  adjustImages(doc, wideBerth);
  return doc.body.innerHTML;
}

const adjustImages = (doc, wideBerth) => {
  doc.querySelectorAll("img").forEach(img => {
    const relative = img.src.split("/").slice(3).join("/");
    img.src = `${process.env.REACT_APP_MEDIA_URL}/${relative}`;
    img.classList.add("clear-both");
    img.classList.add("h-auto");
    img.removeAttribute("width");
    img.removeAttribute("height");
    if (img.classList.contains("right") || img.classList.contains("left")) {
      const div = document.createElement("div");
      img.classList.add("w-full");
      div.appendChild(img.cloneNode());
      img.parentNode.replaceChild(div, img);
      if (img.classList.contains("right")) {
        const space = wideBerth ? "md:ml-16 lg:ml-24" : "md:ml-4 lg:ml-8";
        div.className = `w-full max-w-sm mx-auto md:mx-0 md:max-w-none md:float-right md:w-72 md:clear-both ${space}`;
      } else {
        const space = wideBerth ? "md:mr-16 lg:mr-24" : "md:mr-4 lg:mr-8";
        div.className = `w-full max-w-sm mx-auto md:mx-0 md:max-w-none md:float-left md:w-72 md:clear-both ${space}`;
      }
      if (img.alt) {
        const altTextDiv =  document.createElement("div");
        altTextDiv.className = "text-xs bg-gray-50 p-2 text-center w-full text-gray-700 sm:text-sm";
        altTextDiv.innerHTML = img.alt;
        div.appendChild(altTextDiv);
      }
      const spacer = document.createElement("div");
      spacer.className = "clear-both mt-8";
      div.parentElement.insertBefore(spacer, div);
      let next = div.nextElementSibling?.nextElementSibling?.nextElementSibling;
      while (next && !next.tagName.match(/H[1-6]/)) {
        next = next.nextElementSibling;
      }
      if (next) div.parentElement.insertBefore(spacer.cloneNode(), next);
    } else {
      img.classList.replace("full-width", "w-full");
      img.classList.add("mx-auto");
      img.classList.add("max-w-2xl");
      
      if (img.alt) {
        const altTextDiv = `<div class="text-xs bg-gray-50 p-2 text-center text-gray-700 w-full mx-auto max-w-2xl mb:4 sm:mb-8 sm:text-sm">${img.alt}</div>`;
        img.insertAdjacentHTML("afterend", altTextDiv);
        img.classList.add("mt-4");
        img.classList.add("sm:mt-8");
      } else {
        img.classList.add("my-4");
        img.classList.add("sm:my-8");
      }
    }
  })
  doc.body.appendChild(doc.createElement("div")).className = "clear-both";
}


export const formatDate = dateString => {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "long", year: "numeric" };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
}


export const makeId = text => {
  return text.toLowerCase().replace(/[^a-z0-9]+/g, "-").replace(/-$/, "");
}