import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../utils";
import { Link } from "react-router-dom";

const RecentPosts = props => {

  const { posts } = props;

  return (
    <div>
      <div className="text-2xl mb-6 text-gray-700">Recent Posts</div>
      <div className="flex flex-col gap-6">
        {posts.map((post, index) => (
          <Link to={`/news-events/${post.slug}/`} className="flex gap-4" key={index}>
            <img src={`${process.env.REACT_APP_MEDIA_URL}/${post.image}`} className="w-24 h-24 flex-shrink-0 object-cover" alt={post.title} />
            <div className="w-80">
              <div className="text-secondary mb-2">{post.title}</div>
              <div className="text-xs text-gray-500">{formatDate(post.date)}</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

RecentPosts.propTypes = {
  posts: PropTypes.array.isRequired
};

export default RecentPosts;