import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const ResearchInterests = props => {

  const { title, interestsHtml } = props;

  const textClass = "leading-relaxed text-gray-500 mb-2 mt-2 sm:text-lg lg:mb-3";
  const liClass = textClass.replace("mb-2", "mb-1").replace("mt-2", "mt-1").replace("mb-3", "mb-1");

  const parsedInterestsHtml = parseHtml(interestsHtml, { p: textClass, li: liClass });

  return (
    <div className="bg-gray-100 py-8 sm:py-12">
      <h2 className="title page-width heading border-0">
        Research programme: {title}
      </h2>
      <div
        className="page-width"
        dangerouslySetInnerHTML={{__html: parsedInterestsHtml}}
      />
    </div>
  );
};

ResearchInterests.propTypes = {
  title: PropTypes.string.isRequired,
  interestsHtml: PropTypes.string.isRequired
};

export default ResearchInterests;