import React from "react";
import PropTypes from "prop-types";

const GroupLeaderInfo = props => {

  const { person } = props;

  return (
    <div className="page-width flex gap-x-8 gap-y-6 justify-center flex-col items-center py-12 md:gap-x-12 sm:grid sm:grid-cols-7">
      <img
        alt={`${person.first_name} ${person.last_name}`}
        src={`${process.env.REACT_APP_MEDIA_URL}/${person.image}`}
        className="w-44 h-44 object-cover rounded-full col-span-3 sm:w-56 sm:ml-auto sm:h-56 md:w-64 md:h-64 lg:w-72 lg:h-72 xl:w-80 xl:h-80"
      />
      <div className="text-center sm:text-left sm:col-span-4">
        <div className="font-league font-semibold text-3xl mb-2 text-gray-700 md:text-4xl xl:text-5xl">
          {person.first_name} {person.last_name}
        </div>
        <div className="text-xl text-gray-500 mb-4 xl:text-2xl">{person.role}</div>
        {(person.email || person.phone) && (
          <div className="flex flex-col mb-4 link no-underline gap-2 md:gap-0.5 md:flex-row">
            {person.email && <a href={`mailto:${person.email}`}>{person.email}</a>}
            {person.email && person.phone && <span className="hidden md:block">・</span>}
            {person.phone && <a href={`tel:${person.phone}`}>{person.phone}</a>}
          </div>
        )}
        {person.address && (
          <div><pre className="text-xs font-ss3 mb-4 text-gray-600 font-light leading-relaxed sm:text-sm md:text-lg">{person.address}</pre></div>
        )}
        {person.lab_url && (
          <a href={`${person.lab_url}`} className="block-link inline-block mt-6">Lab Website</a>
        )}
      </div>
    </div>
  );
};

GroupLeaderInfo.propTypes = {
  person: PropTypes.object.isRequired
};

export default GroupLeaderInfo;