import React from "react";
import PropTypes from "prop-types";

const Collaborators = props => {

  const { collaborators } = props;

  const groups = [""];
  for (let collaborator of collaborators) {
    if (collaborator.group) {
      if (!groups.includes(collaborator.group)) {
        groups.push(collaborator.group);
      }
    }
  }

  return (
    <div className="bg-gray-100 py-12 sm:py-12">
      <div className="page-width">
        <h2 className="heading mb-6">Collaborators</h2>
        <div className="flex flex-wrap gap-x-24 gap-y-6">
          {groups.filter(g => collaborators.filter(c => c.group === g).length).map((group, index) => (
            <div className="" key={index}>
              {group && <div className="text-gray-700 text-xl mb-1 font-bold sm:mb-3 sm:text-2xl">{group}</div>}
              <div className="flex flex-col gap-y-1 text-gray-700 sm:gap-y-3 sm:text-lg">
                {collaborators.filter(c => c.group === group).map((collaborator, index) => {
                  if (collaborator.url) {
                    return <a href={collaborator.url} className="link inline-block w-fit" key={index}>{collaborator.name}</a>;
                  } else {
                    return <div key={index}>{collaborator.name}</div>;
                  }
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Collaborators.propTypes = {
  collaborators: PropTypes.array.isRequired
};

export default Collaborators;