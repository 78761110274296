import React from "react";
import { useDocumentTitle, useRemoteData } from "../hooks";
import { useMatch } from "react-router-dom";
import Base from "./Base";
import PersonInfo from "../components/PersonInfo";
import { parseHtml } from "../utils";
import PageNotFound from "./PageNotFound";

const PersonPage = () => {

  const groupSlug = useMatch("/:slug1/:slug2").params.slug1;
  const personSlug = useMatch("/:slug1/:slug2").params.slug2;

  const data = useRemoteData(`people/${groupSlug}/${personSlug}/`);

  useDocumentTitle(data ? `SIDB - ${data.name}` : "SIDB");

  if (!data) return <Base loading={true} />;

  if (data.error) return <PageNotFound />;

  const textClass = "leading-relaxed text-gray-500 mb-2 sm:mb-4 sm:leading-relaxed lg:mb-4 md:text-lg md:leading-relaxed lg:text-xl lg:leading-relaxed";

  const parsedBio = parseHtml(data.bio, {p: textClass, li: textClass})

  return (
    <Base>
      <PersonInfo person={data} />
      <div className="max-w-2xl px-6 md:px-16 mx-auto mb-10 sm:mb-16 md:max-w-3xl lg:max-w-4xl xl:max-w-5xl" dangerouslySetInnerHTML={{__html: parsedBio}} />
    </Base>
  );
};

PersonPage.propTypes = {
  
};

export default PersonPage;