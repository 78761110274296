import React from "react";
import Base from "./Base";
import { useDocumentTitle, useRemoteData } from "../hooks";
import VacanciesIntro from "../components/VacanciesIntro";
import Vacancies from "../components/Vacancies";
import ResearchImages from "../components/ResearchImages";

const VacanciesPage = () => {

  const data = useRemoteData("vacancies/");

  useDocumentTitle("SIDB - Vacancies");

  if (!data) return <Base loading={true} />;

  return (
    <Base>
      <ResearchImages images={[data.header_image]} />
      <VacanciesIntro text={data.text} places={data.places} />
      <Vacancies groups={data.groups} />
    </Base>
  );
};

VacanciesPage.propTypes = {
  
};

export default VacanciesPage;