import React from "react";
import { Link } from "react-router-dom";

const PeopleSwitcher = () => {

  const tabs = [
    ["group-leaders", "Group Leaders"],
    ["researchers", "Researchers"],
    ["professional-scientific-staff", "Professional & Scientific Staff"],
    ["phd-students", "PhD Students"],
    ["executive-team", "Executive Team"],
  ]

  const path = window.location.pathname;

  const className = "cursor-pointer px-3 py-1 rounded";
  const activeClass = `${className} bg-secondary text-white pointer-events-none`;
  const inactiveClass = `${className} link no-underline hover:bg-primary hover:bg-opacity-10`;


  return (
    <div className="hidden text-xs ml-auto w-fit gap-1 mb-12 md:flex lg:text-base">
      {tabs.map((tab, index) => {
        const [slug, name] = tab;
        const isActive = path.includes(slug);
        return (
          <Link to={`/people/${slug}/`} className={isActive ? activeClass : inactiveClass} key={index}>
            {name}
          </Link>
        )
      })}
    </div>
  );
};

PeopleSwitcher.propTypes = {
  
};

export default PeopleSwitcher;