import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const WhyStudy = props => {

  const { html } = props;

  const textClass = "leading-relaxed text-gray-500 mb-2 mt-2 sm:text-lg lg:mb-3";
  const parsedHtml = parseHtml(html, { p: textClass });

  return (
    <div className="py-6 sm:py-12 bg-gray-100">
      <div className="page-width">
        <div className="font-league font-semibold text-2xl mb-2 sm:text-3xl sm:mb-4">Why study with us?</div>
        <div dangerouslySetInnerHTML={{__html: parsedHtml}} />
      </div>
    </div>
  );
};

WhyStudy.propTypes = {
  html: PropTypes.string.isRequired
};

export default WhyStudy;