import React from "react";
import PropTypes from "prop-types";

const PastProjects = props => {

  const { projects } = props;

  const headingClass = "text-gray-700 font-semibold sm:text-lg";
  const projectClass = "text-gray-600 font-medium text-sm sm:text-base";

  return (
    <div className="page-width py-12 sm:py-16">
      <h2 className="heading mb-12">Past PhD Students</h2>
      <div className="flex flex-col gap-6">
        {projects.map((project, index) => {
          return (
            <div key={index}>
              <div className={headingClass}>{project.student}</div>
              <div className={projectClass}>{project.title}</div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

PastProjects.propTypes = {
  projects: PropTypes.array.isRequired
};

export default PastProjects;