import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const ContactParticipate = props => {

  const { h2Class, participateHtml, image } = props;

  const textClass = "text-sm leading-relaxed text-gray-500 mb-2 mt-2 md:text-base md:leading-relaxed lg:mb-8 xl:text-lg xl:leading-relaxed";

  const parsedParticipateHtml = parseHtml(participateHtml, { p: textClass });

  return (
    <div className="grid grid-cols-1 h-fit md:grid-cols-2 lg:grid-cols-7">
      <div className="px-6 py-8 max-w-5xl mx-auto lg:px-12 lg:py-16 lg:col-span-4">
        <h2 className={h2Class}>Taking part in our research studies</h2>
        <div dangerouslySetInnerHTML={{ __html: parsedParticipateHtml }} />
      </div>
      <div className="relative w-full bg-white h-64 flex items-center overflow-hidden md:h-auto lg:col-span-3">
        <img
          alt="participate"
          src={`${process.env.REACT_APP_MEDIA_URL}/${image}`}
          className="absolute w-full h-full object-cover md:p-6"
        />
      </div>
    </div>
  );
};

ContactParticipate.propTypes = {
  h2Class: PropTypes.string.isRequired,
  locationHtml: PropTypes.string.isRequired
};

export default ContactParticipate;