import React from "react";
import PropTypes from "prop-types";

const YoutubeVideos = props => {

  const { videos } = props;

  return (
    <div className="bg-pale-blue page-width py-6 sm:py-12">
      <div className="">
        <h2 className="heading mb-10 xl:mb-16">Videos</h2>
        <div className="w-fit mx-auto flex flex-col gap-x-12 gap-y-8 sm:gap-y-10 lg:flex-row xl:gap-x-16">
          {videos.map((video, index) => (
            <div key={index}>
              <iframe allowfullscreen="" title={`youtube-${index+1}`} className="w-full max-w-sm h-56 mb-3 sm:max-w-none sm:w-96 xl:w-118 xl:h-64 xl:mb-4" type="text/html" frameborder="0" src={video.url} ></iframe>
              <div className="font-league font-semibold text-xl text-center sm:text-2xl xl:text-3xl">{video.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

YoutubeVideos.propTypes = {
  videos: PropTypes.array.isRequired
};

export default YoutubeVideos;