import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const ContactLocation = props => {

  const { h2Class, locationHtml } = props;

  const textClass = "text-sm text-gray-500 mb-2 mt-2 lg:mb-4 md:text-base md:leading-relaxed xl:text-lg xl:leading-relaxed";
  const listClass = `${textClass.replace(/mb-\d/g, "mb-2")}`;

  const parsedLocationHtml = parseHtml(locationHtml, { p: textClass, li: listClass});

  return (
    <div className="grid grid-cols-1 bg-gray-100 md:grid-cols-2 lg:grid-cols-7">
      <div className="px-6 py-8 order-2 max-w-5xl mx-auto md:order-1 lg:col-span-4 lg:px-12 lg:py-16">
        <h2 className={h2Class}>
          Get in touch
        </h2>
        <div dangerouslySetInnerHTML={{ __html: parsedLocationHtml }} />
      </div>
      <iframe
        title="map"
        className="w-full h-64 order-1 md:p-6 md:order-2 md:h-full lg:col-span-3"
        src="https://maps.google.com/maps?q=hugh%20robson%20building&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
        frameborder="0" marginheight="0" marginwidth="0"
      />
    </div>
  );
};

ContactLocation.propTypes = {
  h2Class: PropTypes.string.isRequired,
  locationHtml: PropTypes.string.isRequired
};

export default ContactLocation;