import React from "react";
import Base from "./Base";
import Heading from "../components/Heading";
import Mission from "../components/Mission";
import News from "../components/News";
import { useDocumentTitle, useRemoteData } from "../hooks";
import HomeVideo from "../components/HomeVideo";

const HomePage = () => {

  const data = useRemoteData("");

  useDocumentTitle("SIDB - Home");

  if (!data) return <Base loading={true} />;

  return (
    <Base>
      <Heading images={data.header_images} />
      <Mission aboutHtml={data.about} ethosHtml={data.ethos} />
      <News posts={data.blog_posts} featured={data.featured} />
      {data.video && <HomeVideo url={data.video} title={data.video_title} description={data.video_description} />}
    </Base>
  );
};

HomePage.propTypes = {
  
};

export default HomePage;