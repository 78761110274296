import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { formatDate } from "../utils";
import { ReactComponent as FilterIcon} from "../images/filter.svg";
import colors from "tailwindcss/colors";

const Publications = props => {

  const { title, publications, filterable } = props;

  const [filterText, setFilterText] = useState("");
  const [active, setActive] = useState(false);

  const filteredPublications = publications.filter(publication => {
    const text = `${publication.title} ${publication.authors} ${publication.journal} ${publication.date}`;
    return text.toLowerCase().includes(filterText.toLowerCase());
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const scroll = urlParams.get("scroll");
    if (scroll) {
      const element = document.getElementById(scroll);
      if (element) {
        const yOffset = -100; 
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({top: y, behavior: "smooth"});
      }
    }
  }, []);

  return (
    <div className={`page-width ${props.className || ""}`}>
      <h2 className={`heading mb-4 tsm:mb-6 ${props.h2Class || ""}`}>{title}</h2>
      {filterable && (
        <div className={`flex items-center gap-2 mb-8 focus:border-b ${active ? "" : "opacity-70"}`}>
          <FilterIcon className="inline-block w-5 h-auto opacity-60" fill={colors.slate[400]} />
          <input
            type="text"
            className="block text-2xl text-slate-600 w-full py-1 border-slate-200  outline-none placeholder:text-xl"
            placeholder="Filter..."
            value={filterText}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            onChange={e => setFilterText(e.target.value)}
          />
        </div>
      )}
      <div className="flex flex-col gap-6 md:gap-8">
        {filteredPublications.map((publication, index) => (
          <a id={publication.id} href={`https://pubmed.ncbi.nlm.nih.gov/${publication.pubmed}/`} target="_blank" rel="noreferrer" className="group" key={index}>
            <div className="link text-lg inline-block mb-1 sm:text-xl group-hover:text-orange-2 transition duration-300">{publication.title}</div>
            <div className="text-gray-500 mb-1 text-sm sm:text-base">{publication.authors}</div>
            <div className="text-xs text-gray-700 sm:text-sm">
              Published in: {publication.journal}.
              {publication.date && ` ${formatDate(publication.date)}`}
              {publication.doi && ` (${publication.doi})`}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

Publications.propTypes = {
  title: PropTypes.string.isRequired,
  publications: PropTypes.array.isRequired,
  filterable: PropTypes.bool
};

export default Publications;