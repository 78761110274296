import React from "react";
import { Link } from "react-router-dom";
import { parseHtml } from "../utils";
import PropTypes from "prop-types";

const Mission = props => {

  const { aboutHtml, ethosHtml } = props;

  const headingClass = "text-secondary text-lg mb-2 md:text-2xl"
  const textClass = "text-base mb-5 text-gray-500 md:text-lg";
  const numberClass = `${textClass.replace(/mb-\d/g, "mb-2")}`;

  const parsedAboutHtml = parseHtml(aboutHtml, { p: textClass, li: numberClass });
  const parsedEthosHtml = parseHtml(ethosHtml, { p: textClass, li: numberClass });
  
  return (
    <div className="page-width py-8 lg:py-12">
      <div className="grid grid-cols-1 gap-10 lg:grid-cols-2 lg:gap-12">
        <div className="">
          <h2 className={headingClass}>About us</h2>
          <div dangerouslySetInnerHTML={{ __html: parsedAboutHtml }}/>
          <Link to="/contact/" className="block-link inline-block mt-2 text-sm md:text-base">Contact Us</Link>
        </div>
        <div>
          <h2 className={headingClass}>Our ethos</h2>
          <div dangerouslySetInnerHTML={{ __html: parsedEthosHtml }}/>
        </div>
      </div>
    </div>
  );
};

Mission.propTypes = {
  aboutHtml: PropTypes.string.isRequired,
  ethosHtml: PropTypes.string.isRequired,
};

export default Mission;