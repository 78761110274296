import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const ExecutiveTeam = props => {

  const { people } = props;

  const textClass = "text-xs mb-2 text-center sm:text-left lg:text-sm text-gray-700"

  return (
    <div className="flex flex-col gap-16 sm:gap-16 md:gap-20 lg:gap-24">
      {people.map((person, index) => {

        const bioHtml = parseHtml(person.bio, { p: textClass });

        return (
          <div key={index} className="flex flex-col gap-4 sm:gap-6 sm:flex-row" id={person.id}>
            <img
              alt={person.name}
              src={`${process.env.REACT_APP_MEDIA_URL}/${person.image}`}
              className="w-48 h-48 rounded-full mx-auto object-cover inline-block flex-shrink-0 sm:w-24 sm:h-24 md:h-48 md:w-48 md:mx-0 xl:w-56 xl:h-56"
            />
            <div>
              <div className="font-semibold font-league text-2xl text-center sm:text-left">{person.name}</div>
              <div className="text-lg text-gray-600 text-center sm:text-left">{person.role}</div>
              <a
                href={`mailto:${person.email}`}
                className="block mb-2 link no-underline text-center sm:text-left"
              >
                {person.email}
              </a>
              <div dangerouslySetInnerHTML={{ __html: bioHtml }} />
            </div>
          </div>
        )
      })}
    </div>
  );
};

ExecutiveTeam.propTypes = {
  people: PropTypes.array.isRequired
};

export default ExecutiveTeam;