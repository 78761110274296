import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const Funding = props => {

  const { fundingHtml, externalHtml, grantMembers, strategicMembers, externalMembers } = props;

  const textClass = "text-base mb-5 text-gray-500 md:text-lg";
  const h3Class = "font-league font-semibold text-2xl mb-4 md:text-3xl";
  const headingClass = "font-semibold text-lg mb-2 text-gray-700 md:text-xl";
  const bulletClass = "link mb-1 marker:text-gray-600";

  const parsedFundingHtml = parseHtml(fundingHtml, { p: textClass });
  const parsedExternalHtml = parseHtml(externalHtml, { p: textClass });

  return (
    <div className="-mt-6 sm:-mt-10">
      <div dangerouslySetInnerHTML={{ __html: parsedFundingHtml }} className="mb-6" />
      <h3 className={h3Class}>Internal Strategic Review Board</h3>
      <ul className="mb-10">
        {strategicMembers.map((member, index) => (
          <li key={index} className={bulletClass}><a href={member.url}>{member.name}</a></li>
        ))}
      </ul>
      <h3 className={h3Class}>External Advisory Board</h3>
      <div dangerouslySetInnerHTML={{ __html: parsedExternalHtml }} />
      <div className={headingClass}>External Advisory Board members:</div>
      <ul>
        {externalMembers.map((member, index) => (
          <li key={index} className="mb-1 marker:text-gray-600 text-gray-600">
            <a href={member.url} className="link">{member.name}</a> ({member.affiliation})
          </li>
        ))}
      </ul>
    </div>
  );
};

Funding.propTypes = {
  fundingHtml: PropTypes.string.isRequired,
  externalHtml: PropTypes.string.isRequired,
  grantMembers: PropTypes.array.isRequired,
  strategicMembers: PropTypes.array.isRequired,
  externalMembers: PropTypes.array.isRequired
};

export default Funding;