import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { formatDate, parseHtml } from "../utils";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon
} from "react-share";

const BlogPost = props => {

  const { post } = props;

  const textClass = "text-xs leading-relaxed text-gray-500 mb-2 mt-2 lg:mb-4 sm:text-base xl:text-lg";
  const bodyHtml = parseHtml(post.body, {
    p: textClass, li: textClass, b: "text-gray-700", hr: "mb-4"
  });

  return (
    <div className={props.className || ""}>
      <Link to="/news-events/" className="link block mb-6 no-underline">{"<"} All Posts</Link>
      <h1 className="text-2xl text-secondary mb-2 sm:text-3xl">{post.title}</h1>
      <div className="flex gap-2 text-gray-500 text-sm mb-4 sm:gap-4 sm:mb-6">
        <span>{formatDate(post.date)}</span>
        <span>|</span>
        <span>{post.category}</span>
      </div>
      <div className="text-gray-500 mb-4 sm:text-lg xl:text-xl xl:mb-6">{post.intro_text}</div>
      <div dangerouslySetInnerHTML={{ __html: bodyHtml }} />
      <div className="flex items-center text-sm font-semibold gap-2 text-gray-600 mt-6 sm:mt-12 xl:text-base">
        <div className="mr-3">Share this post:</div>
        <TwitterShareButton url={window.location.href} title={post.title}>
          <TwitterIcon size={24} round={true} />
        </TwitterShareButton>
        <FacebookShareButton url={window.location.href} quote={post.title}>
          <FacebookIcon size={24} round={true} />
        </FacebookShareButton>
        <LinkedinShareButton url={window.location.href} title={post.title}>
          <LinkedinIcon size={24} round={true} />
        </LinkedinShareButton>
      </div>
    </div>
  );
};

BlogPost.propTypes = {
  post: PropTypes.object.isRequired
};

export default BlogPost;