import React from "react";
import sfariLogo from "../images/SFARI.png";
import edinburghLogo from "../images/edinburgh.png";
import pwcLogo from "../images/pwc.png";

const Sponsors = () => {

  const sponsors = [
    {
      url: "https://www.sfari.org/",
      logo: sfariLogo
    },
    {
      url: "https://www.ed.ac.uk/",
      logo: edinburghLogo
    },
    {
      url: "https://patrickwildcentre.com/",
      logo: pwcLogo
    },
  ]

  return (
    <div className="flex flex-wrap justify-center pb-8 px-6 gap-6 sm:pb-10 md:gap-10">
      {sponsors.map((sponsor, index) => (
        <a href={sponsor.url} target="_blank" rel="noopener noreferrer" key={index}>
          <img src={sponsor.logo} alt="sponsor" className="h-8 sm:h-10 md:h-12" />
        </a>
      ))}
    </div>
  );
};

Sponsors.propTypes = {
  
};

export default Sponsors;