import React, { useEffect, useState, useRef } from "react";
import logoWhite from "../images/logo-white.png";
import logo from "../images/logo-hover.png";
import NavLinks from "./NavLinks";
import { Link } from "react-router-dom";

const Nav = () => {

  const isHome = window.location.pathname === "/";

  const menuClass = `h-0.5 w-full ${isHome ? "bg-white" : "bg-gray-900"} rounded group-hover:bg-tertiary`;

  const [showMenu, setShowMenu] = useState(false);
  const [moving, setMoving] = useState(false);

  const ref = useRef();

  const menuClicked = () => {
    setShowMenu(true);
    setMoving(true);
    setTimeout(() => setMoving(false), 500);
  };

  useEffect(() => {
    const onScroll = () => {
      const opacityMultiplier = (Math.min(100, window.scrollY) / 100) * 0.74;
      if (ref.current) {
        ref.current.style.backgroundColor = `rgba(0, 0, 0, ${opacityMultiplier})`;
      }
    }
    if (isHome) {
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }
  }, [isHome])

  return (
    <nav ref={ref} className={`fixed z-50 w-full top-0 h-24 border-gray-100 ${isHome ? "bg-transparent" : "bg-white border-b"}`}>
      <div className="h-full w-full page-width flex justify-between items-center">
        <div className="h-full overflow-hidden">
          <Link to="/" className="h-24 block relative group py-2">
            {isHome && <img src={logoWhite} alt="logo" className="h-full w-auto block " />}
            {!isHome && <img src={logo} alt="logo" className="h-full w-auto block left-0" />}
          </Link>
        </div>
        <div className="w-5 flex flex-col gap-1 cursor-pointer group md:hidden" onClick={menuClicked}>
          <div className={menuClass} />
          <div className={menuClass} />
          <div className={menuClass} />
        </div>
        <NavLinks
          show={showMenu} setShow={setShowMenu}
          moving={moving} setMoving={setMoving}
          isHome={isHome}
        />
      </div>
    </nav>
  );
};

Nav.propTypes = {
  
};

export default Nav;