import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../utils";

const News = props => {

  const { posts, featured } = props;

  const [selected, setSelected] = useState(null);
  const ref = useRef();

  const categories = posts.reduce((p, c) => [...p, ...(p.includes(c.category) ? [] : [c.category])], []);

  const filteredPosts = selected ? posts.filter(p => p.category === selected) : posts;

  const arrowClass = "rotate-45 w-4 h-4 border-gray-400 cursor-pointer";
  const categoryClass = "cursor-pointer px-px hover:text-orange transition duration-300";
  const selectedCategoryClass = `${categoryClass} font-semibold text-orange-2 hover:text-orange-2 pl-0 pr-0`;

  const scrollPrev = () => {
    const currentScroll = ref.current.scrollLeft;
    const width = ref.current.childNodes[0].offsetWidth;
    const gap = ref.current.childNodes[1].offsetLeft - ref.current.childNodes[0].offsetLeft - width;
    const positions = posts.map((p,i) => ((i) * width) + (i * gap));
    positions.reverse();
    for (let position of positions) {
      if (position < currentScroll) {
        ref.current.scrollTo({left: position, behavior: "smooth"});
        break;
      }
    }
  }

  const scrollNext = () => {
    const currentScroll = ref.current.scrollLeft;
    const width = ref.current.childNodes[0].offsetWidth;
    const gap = ref.current.childNodes[1].offsetLeft - ref.current.childNodes[0].offsetLeft - width;
    const positions = posts.map((p,i) => ((i) * width) + (i * gap));
    for (let position of positions) {
      if (position > currentScroll) {
        ref.current.scrollTo({left: position, behavior: "smooth"});
        break;
      }
    }
  }

  return (
    <div className="w-full overflow-auto">
      <div className="py-10 px-6 relative sm:py-12 md:py-16">
        <h2 className="font-league text-4xl text-center font-semibold mb-8 sm:text-5xl">News</h2>

        <Link to={`/news-events/${featured.slug}/`} className="flex flex-col group page-width max-w-4xl w-fit gap-6 mb-6 md:flex-row md:mb-12">
          <img
            src={`${process.env.REACT_APP_MEDIA_URL}/${featured.image}`}
            alt={featured.title}
            className="w-full max-w-sm mx-auto h-auto object-cover md:w-64 md:max-w-none md:mx-0"
          />
          <div className="max-w-sm md:max-w-none">
            <div className="text-orange-2 font-bold text-base group-hover:text-gray-700 transition duration-300 sm:text-lg">{featured.category}</div>
            <div className="text-3xl text-gray-800 font-semibold mb-1 group-hover:text-orange-2 transition duration-300 sm:text-4xl">{featured.title}</div>
            <div className="text-gray-400 text-base mb-2 sm:text-lg">{formatDate(featured.date)}</div>
            <div className="text-gray-500 mb-4 text-lg sm:text-xl">{featured.intro_text}</div>
          </div>
        </Link>

        <div className="flex justify-center flex-wrap gap-x-6 gap-y-2 mb-8 text-gray-400">
          <div
            className={selected ? categoryClass : selectedCategoryClass}
            onClick={() => setSelected(null)}
          >
            All posts
          </div>
          {categories.map((category, i) => (
            <div
              key={i}
              onClick={() => setSelected(category)}
              className={selected === category ? selectedCategoryClass : categoryClass}
            >
              {category}
            </div>
          ))}
        </div>
        <div className="flex w-fit mx-auto gap-2 items-center sm:gap-6">
          <div className={`${arrowClass} border-b-2 border-l-2`} onClick={scrollPrev} />
          <div className="flex w-48 overflow-hidden gap-6 mx-auto sm:w-118 lg:w-180 xl:w-242" ref={ref}>
            {filteredPosts.map((post, i) => (
              <Link to={`/news-events/${post.slug}/`} key={i} className="w-48 flex-shrink-0 h-full border group hover:shadow-md sm:w-56">
                <img src={`${process.env.REACT_APP_MEDIA_URL}/${post.image}`} alt={post.title} className="w-full h-32 object-cover" />
                <div className="bg-white py-6 px-4 text-center h-56 flex flex-col justify-between">
                  <div>
                    <div className="text-sm text-gray-500 mb-3">{formatDate(post.date)}</div>
                    <div className="text-lg mb-4 text-gray-700 hover:text-orange-2 transition duration-300">{post.title}</div>
                  </div>
                  <div className="text-primary hover:text-secondary text-sm">Continue Reading</div>
                </div>
              </Link>
            ))}
          </div>
          <div className={`${arrowClass} border-t-2 border-r-2`} onClick={scrollNext} />
        </div>
      </div>
    </div>
  );
};

News.propTypes = {
  
};

export default News;