import React, { useEffect } from "react";
import Div100vh from "react-div-100vh";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { ClipLoader } from "react-spinners";

const Base = props => {

  const { loading } = props;

  useEffect(() => window.scrollTo({top: 0, left: 0, behavior: "instant"}), []);

  return (
    <Div100vh>
      <Nav />
      {loading && (
        <div className="h-screen bg-white flex justify-center items-center">
          <ClipLoader size={200} speedMultiplier={1.5} color="#547c8a" />  
        </div>
      )}
      {!loading && (
        <main className={`mt-24 bg-white ${props.className || ""}`}>
          {props.children}
        </main>
      )}
      <Footer />
    </Div100vh>
  );
};

Base.propTypes = {

};

export default Base;