import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Heading = props => {

  const { images } = props;

  const [imageIndex, setImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(null);

  const fullImages = images.map(image => `${process.env.REACT_APP_MEDIA_URL}${image.url}`);

  const arrowClass = "w-3 h-3 border-white rotate-45 cursor-pointer sm:w-4 sm:h-4";

  const nextImage = () => {
    setNextImageIndex(imageIndex === fullImages.length - 1 ? 0 : imageIndex + 1);
    setTimeout(() => {
      setNextImageIndex(null);
      setImageIndex(imageIndex === fullImages.length - 1 ? 0 : imageIndex + 1);
    }, 1000);
  }

  const prevImage = () => {
    setNextImageIndex(imageIndex === 0 ? fullImages.length - 1 : imageIndex - 1);
    setTimeout(() => {
      setNextImageIndex(null);
      setImageIndex(imageIndex === 0 ? fullImages.length - 1 : imageIndex - 1);
    }, 1000);
  }

  const imgClass = "absolute w-full h-full object-cover";

  return (
    <div className="relative h-118 -mt-24 overflow-hidden xl:h-128 2xl:h-180">
      {fullImages.map((image, index) => {
        const offScreen = index !== imageIndex && index !== nextImageIndex;
        const onTop = index === imageIndex;
        const fadingOut = index === imageIndex && nextImageIndex !== null;
        return (
          <img src={image} alt="" key={index} className={`${imgClass} ${onTop ? "z-20" : "z-10"} ${offScreen ? "left-full" : "left-0"} ${fadingOut ? "opacity-0 transition-opacity duration-1000" : ""}`} />
        )
      })}
      <div className="absolute top-0 pt-10 left-0 w-full h-full bg-black bg-opacity-50 flex z-30 flex-col items-center justify-center gap-10 px-10">
        <div className="flex flex-col gap-10 items-center xl:border-l xl:border-white xl:-ml-56 xl:pl-10 xl:items-start">
          <h1 className="text-white font-league text-center font-semibold leading-10 text-3xl sm:text-5xl sm:leading-snug md:text-5xl md:leading-relaxed lg:text-6xl lg:leading-normal xl:text-7xl xl:text-left xl:leading-none">
            Discovering the <br className="hidden xl:inline" />biological <br className="hidden sm:inline xl:hidden" /> mechanisms <br className="hidden xl:inline" />underlying autism
          </h1>
          <Link to={images[imageIndex].button_link} className="transition duration-300 block-link w-fit">
            {images[imageIndex].button_text}
          </Link>
        </div>
        <div className={`absolute text-white w-full bottom-6 flex justify-between px-6 ${nextImageIndex === null ? "" : "pointer-events-none"}`}>
          <div className={`${arrowClass} border-l-2 border-b-2`} onClick={prevImage}></div>
          <div className={`${arrowClass} border-r-2 border-t-2`} onClick={nextImage}></div>
        </div>
      </div>
    </div>
  );
};

Heading.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Heading;