import React, { useEffect } from "react";

const Twitter = () => {

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  return (
    <div className="pt-10 border-t mt-8">
      <a className="twitter-timeline w-full" data-lang="en" data-width="" data-height="800" href="https://twitter.com/sidb_edinburgh?ref_src=twsrc%5Etfw">
        Tweets by SIDB
      </a>
    </div>
  );
};

Twitter.propTypes = {
  
};

export default Twitter;