import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const WhatWeDoBlocks = props => {

  const { blocks } = props;

  const textClass = "leading-relaxed text-gray-500 mb-3 mt-2 text-sm md:text-base md:leading-relaxed lg:text-lg lg:leading-relaxed lg:mb-4";

  return (
    <div className="bg-pale-blue page-width py-12 flex flex-col gap-y-16 sm:py-16 lg:gap-y-24 xl:gap-y-32">
      {blocks.map((block, index) => {
        const parsedHtml = parseHtml(block.text, { p: textClass });
        const isRight = index % 2 !== 0;
        return (
          <div key={index} className={`flex flex-col items-center gap-12 ${isRight ? "sm:flex-row-reverse" : "sm:flex-row"}`}>
            <img
              alt={block.title}
              src={`${process.env.REACT_APP_MEDIA_URL}/${block.image}`}
              className="w-48 h-48 object-cover rounded-full flex-shrink-0 md:w-56 md:h-56 lg:w-72 lg:h-72 xl:w-96 xl:h-96"
            />
            <div className="">
              <div className="font-league font-semibold text-2xl mb-3 sm:text-3xl">{block.title}</div>
              <div dangerouslySetInnerHTML={{ __html: parsedHtml }} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

WhatWeDoBlocks.propTypes = {
  blocks: PropTypes.array.isRequired
};

export default WhatWeDoBlocks;