import React from "react";
import PropTypes from "prop-types";
import defaultImg from "../images/default.png";

const PersonInfo = props => {

  const { person } = props;

  return (
    <div className="page-width flex gap-x-12 gap-y-6 justify-center flex-col items-center py-12 sm:grid sm:grid-cols-7">
      <img
        alt={person.name}
        src={person.image ? `${process.env.REACT_APP_MEDIA_URL}/${person.image}` : defaultImg}
        className="w-36 h-36 object-cover rounded-full col-span-3 sm:w-56 sm:ml-auto sm:h-56 md:w-64 md:h-64 lg:w-80 lg:h-80 xl:w-96 xl:h-96"
      />
      <div className="text-center sm:text-left sm:col-span-4">
        <div className="font-league font-semibold text-3xl mb-2 text-gray-700 lg:text-4xl xl:text-5xl">
          {person.name}
        </div>
        <div className="text-xl text-gray-500 mb-4 xl:text-2xl">{person.is_phd ? "PhD Student" : person.role}</div>
        {person.email && (
          <div className="flex flex-col mb-4 link no-underline gap-2 md:gap-0.5 md:flex-row">
            {person.email && <a href={`mailto:${person.email}`}>{person.email}</a>}
          </div>
        )}
        <div className="flex mt-6 gap-4">
          <a href={`/${person.pi.slug}/`} className="block-link inline-block">
            {person.pi.last_name} Lab
          </a>
          {person.secondary_group_leader && (
            <a href={`/${person.secondary_group_leader.slug}/`} className="block-link inline-block">
              {person.secondary_group_leader.last_name} Lab
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

PersonInfo.propTypes = {
  person: PropTypes.object.isRequired
};

export default PersonInfo;