import React from "react";
import Base from "./Base";
import { useDocumentTitle, useRemoteData } from "../hooks";
import ContactLocation from "../components/ContactLocation";
import ContactParticipate from "../components/ContactParticipate";
import ResearchImages from "../components/ResearchImages";

const ContactPage = () => {

  const data = useRemoteData("contact/");

  useDocumentTitle("SIDB - Contact");

  if (!data) return <Base loading={true} />;

  const h2Class = "heading border-gray-300 pb-2 mb-6 pb-2";

  return (
    <Base>
      <ResearchImages images={[data.header_image]} />
      <ContactLocation locationHtml={data.location} h2Class={h2Class} />
      <ContactParticipate participateHtml={data.participate} h2Class={h2Class} image={data.participate_image} />
    </Base>
  );
};

ContactPage.propTypes = {
  
};

export default ContactPage;