import React from "react";
import PropTypes from "prop-types";

const HomeVideo = props => {

  const { url, title, description } = props;

  return (
    <div className="bg-[#56a0c7] flex flex-col justify-center items-center py-12 sm:py-16">
      {title && <h2 className="max-w-3xl text-center page-width heading border-b-0">{title}</h2>}
      {description && <div className="max-w-3xl text-center page-width mb-8 text-base text-gray-800 md:text-lg">{description}</div>}
      <iframe
        allowfullscreen=""
        title="SIDB"
        className="w-full max-w-sm h-64 sm:max-w-none sm:w-118 md:w-128 md:h-80 2xl:w-180 2xl:h-96"
        type="text/html"
        frameborder="0"
        src={url}
      >
      </iframe>
    </div>
  );
};

HomeVideo.propTypes = {
  
};

export default HomeVideo;