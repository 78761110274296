import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const VacanciesIntro = props => {

  const { text, places } = props;

  const textClass = "leading-relaxed text-gray-500 mb-2 mt-2 sm:text-lg lg:mb-3";

  const parsedTexthtml = parseHtml(text, { p: textClass });
  const parsedPlaceshtml = parseHtml(places, { p: textClass });

  return (
    <div className="py-6 bg-gray-100 sm:py-12">
      <div className="page-width">
        <h1 className="heading mb-2 text-4xl sm:mb-4 sm:text-5xl">Job vacancies at the SIDB</h1>
        <div className="flex gap-y-6 flex-col gap-x-12 lg:flex-row xl:gap-x-16">
          <div dangerouslySetInnerHTML={{__html: parsedTexthtml}} />
          <div>
            <div className="font-semibold font-league text-2xl">Current vacancies</div>
            <div dangerouslySetInnerHTML={{__html: parsedPlaceshtml}} />
          </div>
        </div>
      </div>
    </div>
  );
};

VacanciesIntro.propTypes = {
  text: PropTypes.string.isRequired,
  places: PropTypes.string.isRequired
};

export default VacanciesIntro;