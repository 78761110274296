import React from "react";
import Base from "./Base";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "../hooks";

const PageNotFound = () => {

  useDocumentTitle("SIDB - Page Not Found");

  return (
    <Base>
      <h1 className="text-center font-league text-6xl font-semibold mb-8 pt-24 2xl:pt-48">Page Not Found</h1>
      <div className="text-center text-gray-600 text-xl mb-8">
        We can’t seem to find the page you’re looking for.
      </div>
      <Link to="/" className="block-link h-fit px-8 w-fit block mx-auto bg-gray-900 text-white hover:bg-gray-800 2xl:mb-48">
        Go to Home
      </Link>
      <div className="h-24" />
    </Base>
  );
};

PageNotFound.propTypes = {
  
};

export default PageNotFound;