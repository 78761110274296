import React from "react";
import { useDocumentTitle, useRemoteData } from "../hooks";
import { Link, useParams } from "react-router-dom";
import Base from "./Base";
import PageNotFound from "./PageNotFound";
import { parseHtml } from "../utils";

const PhdProjectPage = () => {

  const { slug } = useParams();

  const data = useRemoteData(`phds/${slug}/`);

  useDocumentTitle(data ? `SIDB - ${data.title}` : "SIDB");

  if (!data) return <Base loading={true} />;

  if (data.error) return <PageNotFound />;

  const textClass = "leading-relaxed text-gray-500 mb-3 mt-2 sm:text-lg sm:leading-relaxed lg:mb-4";
  const classes  = {p: textClass, li: textClass, b: "text-gray-700", hr: "mb-4"};

  const backgroundHtml = data.background ? (
    parseHtml(data.background, classes)
  ) : null;

  const rationaleHtml = data.rationale_hypothesis ? (
    parseHtml(data.rationale_hypothesis, classes)
  ) : null;

  const aimsHtml = data.aims ? (
    parseHtml(data.aims, classes)
  ) : null;

  const trainingHtml = data.training_outcomes ? (
    parseHtml(data.training_outcomes, classes)
  ) : null;

  const referencesHtml = data.references ? (
    parseHtml(data.references, classes)
  ) : null;

  const sections = [
    {title: "Background", html: backgroundHtml},
    {title: "Rationale and hypothesis", html: rationaleHtml},
    {title: "Aims", html: aimsHtml},
    {title: "Training outcomes", html: trainingHtml},
    {title: "References", html: referencesHtml}
  ].filter(section => section.html);

  return (
    <Base className={sections.length === 0 ? "py-12 sm:py-16" : "pt-12 sm:pt-16"}>
      <div className="page-width">
        <h1 className="heading mb-8 text-4xl sm:mb-12 sm:text-5xl sm:leading-tight">{data.title}</h1>
        {data.primary_lab && (
          <Link className="text-lg flex items-center -mt-4 mb-8 link no-underline sm:mb-8 sm:text-xl" to={`/${data.primary_lab.slug}/`}>
            {data.primary_lab.image && (
              <img
                src={`${process.env.REACT_APP_MEDIA_URL}/${data.primary_lab.image}`}
                alt={`${data.primary_lab.first_name} ${data.primary_lab.last_name}`} 
                className="hidden w-10 h-10 object-cover rounded-full border border-gray-300 mr-3 sm:inline-block" 
              />
            )}
            {data.primary_lab.first_name} {data.primary_lab.last_name}
            <span className="text-gray-500 ml-2">(Primary Supervisor)</span>
          </Link>
        )}
        <div className="leading-relaxed text-lg text-gray-500 mb-3 mt-2 sm:text-xl sm:leading-relaxed lg:mb-4">{data.description}</div>
      </div>
      {sections.map((section, index) => (
        <section key={index} className={`py-8 sm:py-12 ${index % 2 === 0 ? "" : "bg-gray-100"}`}>
          <div className="page-width">
            <h2 className="heading mb-4 sm:mb-6">{section.title}</h2>
            <div dangerouslySetInnerHTML={{__html: section.html}}></div>
            {index === sections.length - 1 && (
              <a href="mailto:sidbadm@ed.ac.uk" className="block-link mt-4 inline-block sm:mt-6">
                Apply now
              </a>
            )}
          </div>
        </section>
      ))}
    </Base>
  );
};

PhdProjectPage.propTypes = {
  
};

export default PhdProjectPage;