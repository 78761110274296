import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Select from "react-select";
import { formatDate } from "../utils";
import Twitter from "./Twitter";

const BlogPosts = props => {

  const { posts } = props;

  const [showing, setShowing] = useState(10);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const categories = [...new Set(posts.map(post => post.category))];
  categories.sort();

  const options = [
    { value: null, label: "All Posts" },
    ...categories.map(category => ({ value: category, label: category }))
  ];
  const selectedOption = options.find(option => option.value === selectedCategory);

  const filteredPosts = selectedCategory ? posts.filter(post => post.category === selectedCategory) : posts;

  const visiblePosts = filteredPosts.slice(0, showing);

  return (
    <div className="page-width pt-8 pb-12 sm:pt-8 sm:pb-16">
      <h2 className="heading mb-6 text-4xl sm:mb-10 sm:text-5xl">Blog</h2>
      <div className="flex flex-col gap-10 lg:flex-row">
        <div className="flex flex-col gap-10 lg:border-r lg:pr-8">
          {visiblePosts.map((post, index) => (
            <Link to={`/news-events/${post.slug}/`} key={index} className={`flex flex-col gap-6 group items-start pb-10 sm:flex-row sm:gap-10 md:gap-6 lg:flex-row lg:gap-10 ${index === visiblePosts.length - 1 || "border-b"}`}>
              <img
                src={`${process.env.REACT_APP_MEDIA_URL}/${post.image}`}
                alt={post.title}
                className="w-56 flex-shrink-0 h-auto"
              />
              <div className="">
                <div className="text-orange-2 font-bold text-sm group-hover:text-gray-700 transition duration-300">{post.category}</div>
                <div className="text-2xl text-gray-800 font-semibold mb-1 group-hover:text-orange-2 transition duration-300">{post.title}</div>
                <div className="text-gray-400 text-sm mb-2">{formatDate(post.date)}</div>
                <div className="text-gray-500 mb-4">{post.intro_text}</div>
              </div>
            </Link>
          ))}
          {filteredPosts.length > showing && (
            <div className="text-center link cursor-pointer no-underline">
              <div onClick={() => setShowing(showing + 5)}>+ Show More</div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-3 w-full pt-12 border-t flex-shrink-0 lg:w-80 lg:pt-0 lg:border-t-0 xl:w-96">
          <div className="text-2xl mb-1">Categories</div>
          <Select
            options={options}
            value={selectedOption}
            onChange={option => setSelectedCategory(option.value)}
            className="cursor-pointer"
            classNames={{
              input: () => "cursor-pointer",
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                cursor: "pointer",
                borderColor: state.isFocused ? "#547c8a" : "",
                "&:hover": {
                  borderColor: "#547c8a",
                },
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isSelected ? "#547c8a" : state.isFocused ? "#9dd1e3" : "",
                cursor: "pointer",
              }),
            }}

          />
          <Twitter />
        </div>
      </div>
    </div>
  );
};

BlogPosts.propTypes = {
  posts: PropTypes.array.isRequired
};

export default BlogPosts;