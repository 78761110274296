import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const ResearchSummaryImage = props => {

  const { image, captionHtml } = props;

  const textClass = "text-sm leading-relaxed text-gray-500 mb-1 mt-2 sm:text-base lg:text-lg";

  const parsedCaptionHtml = parseHtml(captionHtml, { p: textClass });

  return (
    <div className="py-12 page-width sm:py-16">
      <img src={`${process.env.REACT_APP_MEDIA_URL}${image}`} alt="" className="w-full max-w-4xl mx-auto" />
      {captionHtml && (
        <div
          dangerouslySetInnerHTML={{__html: parsedCaptionHtml}}
          className={`mx-auto max-w-4xl mt-4 ${captionHtml.length < 200 ? "text-center" : ""}}`}
        />
      )}
    </div>
  );
};

ResearchSummaryImage.propTypes = {
  image: PropTypes.string.isRequired,
  captionHtml: PropTypes.string.isRequired,
};

export default ResearchSummaryImage;