import React from "react";
import Base from "./Base";
import { useDocumentTitle, useRemoteData } from "../hooks";
import Publications from "../components/Publications";

const PublicationsPage = () => {

  const data = useRemoteData("publications/");

  useDocumentTitle("SIDB - Publications");

  if (!data) return <Base loading={true} />;

  return (
    <Base className="py-6 sm:py-12">
      <Publications
        publications={data} title="Publications" filterable={true}
        className="pb-8 sm:pb-12" h2Class="text-4xl sm:text-5xl"
      />
    </Base>
  );
};

PublicationsPage.propTypes = {
  
};

export default PublicationsPage;