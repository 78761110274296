import React from "react";
import Base from "./Base";
import { useDocumentTitle, useRemoteData } from "../hooks";
import NewsIntro from "../components/NewsIntro";
import NewsGrid from "../components/NewsGrid";
import BlogPosts from "../components/BlogPosts";

const NewsEventsPage = () => {

  const data = useRemoteData("news-events/");

  useDocumentTitle("SIDB - News & Events");

  if (!data) return <Base loading={true} />;

  return (
    <Base>
      <NewsIntro introHtml={data.intro} />
      <NewsGrid
        resourcesHtml={data.resources}
        communityEventsHtml={data.community_events}
        seminarsTalksHtml={data.seminars}
        resourcesImage={data.resources_image}
        communityEventsImage={data.community_events_image}
        seminarsTalksImage={data.seminars_image}
      />
      <BlogPosts posts={data.blog_posts} />
    </Base>
  );
};

NewsEventsPage.propTypes = {
  
};

export default NewsEventsPage;