import React from "react";
import PropTypes from "prop-types";
import { parseHtml } from "../utils";

const Resource = props => {

  const { resource } = props;

  const textClass = "text-xs leading-relaxed text-center text-gray-500 mb-2 mt-2 sm:text-sm lg:mb-4";
  
  const parsedHtml = parseHtml(resource.description, { p: textClass });

  return (
    <div className="w-full max-w-xs flex flex-col justify-between items-center sm:w-80">
      <div className="font-semibold text-center text-xl mb-4 sm:text-2xl">{resource.name}</div>
      <img src={`${process.env.REACT_APP_MEDIA_URL}/${resource.image}`} alt="resource" className="w-1/2 mb-2" />
      <div dangerouslySetInnerHTML={{ __html: parsedHtml }} />
    </div>
  )
};

Resource.propTypes = {
  resource: PropTypes.object.isRequired
};

export default Resource;