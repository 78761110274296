import React from "react";
import Base from "./Base";
import Studentships from "../components/Studentships";
import { useDocumentTitle, useRemoteData } from "../hooks";
import WhyStudy from "../components/WhyStudy";
import PhdDeadline from "../components/PhdDeadline";
import LabMembers from "../components/LabMembers";
import PastProjects from "../components/PastProjects";

const PhdPage = () => {

  const data = useRemoteData("phds/");

  useDocumentTitle("SIDB - PhD Projects");

  if (!data) return <Base loading={true} />;

  const deadlineImages = [
    data.deadline_image1,
    data.deadline_image2,
    data.deadline_image3,
    data.deadline_image4,
  ].filter((image) => image);

  return (
    <Base>
      <Studentships
        html={data.about}
        open_projects_title={data.open_projects_title}
        phd_projects={data.phd_projects}
      />
      <WhyStudy html={data.why_study} />
      <PhdDeadline images={deadlineImages} html={data.deadline} />
      <div className="bg-gray-100 py-12 sm:py-16">
        <div className="page-width">
          <h2 className="heading mb-12">SIDB PhD Students</h2>
          <LabMembers people={data.phd_students} link={true} />
        </div>
      </div>
      <PastProjects projects={data.past_projects} />
    </Base>
  );
};

PhdPage.propTypes = {
  
};

export default PhdPage;