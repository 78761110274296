import React from "react";
import Base from "./Base";
import { useMatch } from "react-router-dom";
import { useDocumentTitle, useRemoteData } from "../hooks";
import GroupLeaderInfo from "../components/GroupLeaderInfo";
import GroupBackground from "../components/GroupBackground";
import ResearchImages from "../components/ResearchImages";
import ResearchInterests from "../components/ResearchInterests";
import Publications from "../components/Publications";
import LabMembers from "../components/LabMembers";
import ResearchSummaryImage from "../components/ResearchSummaryImage";
import Collaborators from "../components/Collaborators";
import PageNotFound from "./PageNotFound";

const GroupPage = () => {

  const slug = useMatch("/:slug/").params.slug;

  const data = useRemoteData(`people/${slug}/`);

  useDocumentTitle(data ? `SIDB - ${data.last_name} Lab` : "SIDB");

  if (!data) return <Base loading={true} />;

  if (data.error) return <PageNotFound />;

  const people = [...data.researchers, ...data.staff, ...data.phd_students.map(person => ({ ...person, role: "PhD Student" }))];

  return (
    <Base>
      <GroupLeaderInfo person={data} />
      {data.background && <GroupBackground backgroundHtml={data.background} />}
      {data.research_images.length > 0 && <ResearchImages images={data.research_images} />}
      {data.research_interests && <ResearchInterests title={data.research_programme_name} interestsHtml={data.research_interests} />}
      {data.research_image && <ResearchSummaryImage image={data.research_image} captionHtml={data.research_image_caption} />}
      {data.collaborators.length > 0 && <Collaborators collaborators={data.collaborators} />}
      {data.publications.length > 0 && <Publications publications={data.publications} title="Selected publications" className="py-6 sm:py-16" />}
      {people.length > 0 && (
        <div className="page-width py-12 sm:py-16">
          <h2 className="heading mb-6 sm:mb-16">Lab Members</h2>
          <LabMembers people={people} />
        </div>
      )}
      {data.lab_row_images.length > 0 && <ResearchImages images={data.lab_row_images} />}
      {data.lab_grid_images.length > 0 && <ResearchImages images={data.lab_grid_images} grid={true} />}
    </Base>
  );
};

GroupPage.propTypes = {
  
};

export default GroupPage;