import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../images/search.svg";
import ClipLoader from "react-spinners/ClipLoader";

const SearchInterface = props => {

  const { setShow } = props;

  const ref1 = useRef();
  const ref2 = useRef();
  const inputRef = useRef();
  const timeout = useRef(null);

  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  const textEntered = e => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      setResults(null);
      setLoading(false);
      return;
    }
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setLoading(true);
      fetch(`${process.env.REACT_APP_BACKEND_URL}/search/?query=${e.target.value}`, {
        method: "GET",
        headers: {"Content-Type": "application/json"}
      }).then(res => res.json()).then(data => {
        setResults(data.results);
        setLoading(false);
      }).catch(err => {
        console.log(err);
        setLoading(false);
      });
    }, 500)
  }

  useEffect(() => {
    const handleClickOutside = e => {
      if (ref1.current && !ref1.current.contains(e.target) && ref2.current && !ref2.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref1, ref2, setShow]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className="w-screen h-screen left-0 top-0 fixed bg-black bg-opacity-50">
      <div className="page-width h-screen flex flex-col py-28">
        <div className="relative" ref={ref1}>
          <div className="h-14 w-14 absolute top-0 left-0 flex items-center justify-center">
            {!loading && <SearchIcon className="fill-gray-300 inline-block w-auto h-full p-3.5" />}
            {loading && <ClipLoader color="#6d9bab" size={22} speedMultiplier={1.6} />}
          </div>
          <input
            type="text"
            ref={inputRef}
            className="block flex-grow w-full rounded pl-14 pr-4 h-14 text-xl outline-none border text-primary border-gray-300 placeholder:text-gray-300"
            placeholder="Search..."
            value={searchText}
            onChange={textEntered}
          />

        </div>
        <div className="bg-gray-100 rounded mt-4 flex flex-col gap-y-px overflow-y-auto" ref={ref2}>
          {results && searchText && results.map((result, index) => {
            const textSize = result.type === "Publication" ? "text-base sm:text-lg" : "text-lg sm:text-xl";
            return (
              <Link to={result.url} key={index} className="block px-4 py-2 bg-white cursor-pointer hover:bg-gray-100" onClick={() => setShow(false)}>
                <div className={`${textSize} text-slate-700`}>{result.name}</div>
                <div className="text-orange-2 text-sm font-semibold">{result.type}</div>
              </Link>
            )
          })}
          {results && searchText && !loading && results.length === 0 && (
            <div className="text-base px-4 py-2 sm:text-lg text-slate-700">No results found</div>
          )}
        </div>
      </div>
    </div>
  );
};

SearchInterface.propTypes = {
  setShow: PropTypes.func.isRequired
};

export default SearchInterface;